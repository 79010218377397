.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
  // transition: height 0.2s;
  align-items: flex-start; /* Add this */
}
.embla__slide {
  position: relative;
  flex: 0 0 100%;
}

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #1bcacd;
  padding: 0;
}

.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button__svg {
  width: 100%;
  height: 100%;
}

.embla__button--prev {
  left: 27px;
}

.embla__button--next {
  right: 27px;
}

.embla__dots {
  display: flex;
  list-style: none;
  justify-content: center;
  padding-top: 10px;
}

.embla__dot {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  width: 30px;
  height: 30px;
  margin-right: 7.5px;
  margin-left: 7.5px;
  display: flex;
  align-items: center;
}

.embla__dot:after {
  background-color: #ccc;
  width: 100%;
  height: 6px;
  border-radius: 2px;
  content: "";
}

.embla__dot.is-selected:after {
  background-color: $blue;
  opacity: 1;
  box-shadow: 0 0 2px $blue;
}
