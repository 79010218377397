#pages-home {
  img.home-gecko {
    width: 200px;
    position: absolute;
    top: -150px;
    right: 0;
  }
  .welcome-content {
    line-height: 1.3em;
    font-size: 1.2em;

    p { 
      margin-bottom: 0;
    }
  }
}

#pages-about {
  .card.program {
    // This rounds the top corners of the truck images
    overflow: hidden;

    .sponsored-by-geico:before {
      content: "\a";
      white-space: pre;
    }

    .patrol-name-small {
      // font-size: 95%;
    }
    .patrol-name-x-small{
      font-size: 15px;
    }
    .sponsored-by-geico {
      font-size: 19.2px;
    }

    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &:hover {
      background-color: #fefefe;
      box-shadow: 0 0.2rem 0.5rem rgb(0 0 0 / 10%) !important
    }
  }
  .card-text {
    height: 58px;
  }
  @include media-breakpoint-down(xxl) {
    .card-text{
      height: 58px;
    }
  }
  @include media-breakpoint-down(xl) {
    .card-text{
      height: 87px;
    }
  }
  @include media-breakpoint-down(lg) {
    .card-text{
      height: 116px;
    }
  }
  @include media-breakpoint-down(md) {
    .card-text{
      height: 60px;
    }
  }
  @include media-breakpoint-down(sm) {
    .card-text{
      height: auto;
    }
  }
}

a.media-kit-link {
  position: relative;
  display: block;

  img.icon { 
    width: 20px;
    position: absolute;
    right: 0;
    top: 5px;
  }
}
img.media-kit {
  // TODO
}
