$heroTextHeight: 250px;

#hero-truck {
  background-image: url("~images/hero-poster.jpg");
  height: 600px;
  min-height: 200px;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;

  .gradient-mask {
    display: block;
    position: absolute;
    bottom: 0;
    height: 250px;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba($blue,0), $blue);
  }
  
  video {
    min-width: 100%;
  }

  h1 {
    position: absolute;
    bottom: 1.0rem;
    color: $white;
    font-size: 4.0em;
    z-index: 100;
    font-weight: bold;
  }
}

#hero-gecko {
  // padding: 1.5em 2.0em 1.0em 2.0em;

  img {
    margin-top: 0px;
    margin-right: 20px;
    max-height: 180px;
    float: right;
  }
  h1 {
    font-size: 3.4rem;
    font-weight: 600;
    line-height: 1.1em;
    color: $darkBlue;
  }
}

@include media-breakpoint-up(md) {
  #hero-truck {
    video {
      margin-top: -75px;
    }
    min-height: 250px;
  }
}
@include media-breakpoint-up(lg) {
  #hero-truck {
    video {
      margin-top: -125px;
    }
    min-height: 325px;
  }
}
@include media-breakpoint-up(xl) {
  #hero-truck {
    video {
      margin-top: -175px;
    }
    min-height: 425px;
  }
}
@include media-breakpoint-down(lg) {
  #hero-truck {
    h1 {
      // font-size: 3.2rem;
      line-height: 1.0em;
      max-width: 475px;
    }
  }
}
@include media-breakpoint-down(sm) {
  #hero-gecko {
    img {
      max-height: 120px;
    }
    h1 {
      font-size: 2.6rem;
      line-height: 1.0em;
    }
  }
}