// https://bootrails.com/blog/rails-webpacker-full-setup
// https://getbootstrap.com/docs/5.0/customize/optimize/

// First, we import customizations
@import '../css/customized_bootstrap.scss';

// Then, we import the rest of Bootstrap according to our needs!

// This would just import all of bootstrap, rather than picking and choosing modules
// @import "~bootstrap/scss/bootstrap";


// These two are present in customized_bootstrap
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
// @import "~bootstrap/scss/accordion";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
// @import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
// @import "~bootstrap/scss/offcanvas";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

@import '../css/common.scss';
@import '../css/forms.scss';
@import '../css/header.scss';
@import '../css/footer.scss';

@import '../css/pages';
@import '../css/hero';
@import '../css/survey';
@import '../css/carousel';
@import '../css/testimonials';


@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
