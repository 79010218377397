// Don't show the caret next to the "Menu" dropdown
.nav-link.dropdown-toggle {
  padding: 3px 10px;
  font-size: 1.0rem;
  background-color: #1C3665;
  .navbar-toggler-icon {
    font-size: 0.8rem;
    margin-bottom: 0.1rem;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1.0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  &:link {
    color: #fff;
  }
  &::after {
    content: none;
  }
}

@include media-breakpoint-down(md) {
  p { font-size: 15pt; }
  label.form-label {
    display: block;
    font-size: 15pt;
  }
}