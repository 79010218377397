  .embla {
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 50px;

    .embla__slide {
      list-style-type: none;

      blockquote {
        background: #f9f9f9;
        border-left: 10px solid #ccc;
        margin: 1.5em 1.0em 1.5em 1.0em;
        padding: 2.0em 2.0em 1.0em 2.0em;
        quotes: "\201C""\201D""\2018""\2019";
        z-index: 10;
        &:last-child {
          margin-bottom: 0;
        }
      }

      blockquote:before {
        position: absolute;
        color: #ccc;
        content: open-quote;
        font-size: 3em;
        line-height: 0.1em;
        margin-top: 0.2em;
        margin-left: -0.2em;
      }
    
      // The testimony
      blockquote > :first-child {
        text-indent: 0.6em;

        &:after {
          display: inline;
          color: #ccc;
          content: close-quote;
          font-size: 3em;
          line-height: 12px;
          vertical-align: bottom;
          margin-left: 5px;
          z-index: 1;
        }
      }

      .author { text-align: right; color: #888; margin-bottom: 0; }
      .author::before { content: '- '; }
    }
  }


@include media-breakpoint-down(md) {
  .embla { margin-left: -2.0em; margin-right: -2.0em }
}