header {
  &.bg-blue, .bg-blue { 
    background-color: $blue; 
    padding: 0.4em;

    a {
      color: #fff;
    }
  }

  .navbar {
    .navbar-brand {
      font-size: 1.4rem;
      font-weight: 300;
      img { 
        margin-left: 3px; margin-top: -5px; height: 0.7em; 
        image-rendering: -webkit-optimize-contrast;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  header {
    .navbar-brand {
      display: block;
      width: 100%;
      margin: 0;
      font-size: 18px;
    }
  }
}

@include media-breakpoint-down(sm) {
  header {
    .navbar {
      .navbar-brand {
        font-size: 18px;
      }
    }
  }
}