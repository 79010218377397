// Set Bootstrap Variables
// https://getbootstrap.com/docs/5.0/customize/css-variables/
// https://getbootstrap.com/docs/5.0/customize/options/
// https://bootrails.com/blog/rails-bootstrap-custom-theme
// https://rstudio.github.io/bslib/articles/bs5-variables.html


/** 
* 1 - Set SCSS variables below
*/

$font-family-base: 'Lato', sans-serif;

$font-size-base: 1.2rem;

// https://getbootstrap.com/docs/5.0/customize/options/
$blue: #4672C3;
$lightBlue: #8CA7D8;
$darkBlue: darken($blue, 10%);
$green: #4F8143;

// https://getbootstrap.com/docs/5.0/components/navbar/
$dark: $darkBlue;

// https://getbootstrap.com/docs/5.0/customize/color/
// https://getbootstrap.com/docs/5.0/customize/sass/#maps-and-loops
$success: $green;

// https://getbootstrap.com/docs/5.0/forms/select/
$input-padding-y: 10px;
$input-padding-x: 20px;
$input-color: $blue;
$input-font-weight: 600;
$input-border-width: 3px;
$input-border-color: $blue;
$input-border-radius: 30px;

// https://getbootstrap.com/docs/5.0/forms/checks-radios/
$form-check-input-width: 2.0em;
$form-check-input-border: $input-border-width solid $input-border-color;

// https://getbootstrap.com/docs/5.0/forms/range/
$form-range-track-bg: lighten($blue, 25%);

// https://getbootstrap.com/docs/5.0/components/buttons/
$btn-padding-y: 10px;
$btn-padding-x: 20px;
$btn-border-radius: 30px;
$btn-border-width: 2px;
$btn-link-color: #888;

$form-select-focus-border-color: $lightBlue;

$form-label-font-size: 1.2em;
$form-label-color: $darkBlue;

// https://getbootstrap.com/docs/5.0/components/progress/
$progress-height: 0.4rem;
$progress-bg: darken($blue, 25%);
$progress-bar-bg: lighten($blue, 25%);

.fs-7 {
  font-size: 1.0rem;
}

/** 
* 2 - Import bare minimum of bootstrap
*/
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/utilities";

/** 
* 3 - Now you can refine Bootstrap here
*/

// https://getbootstrap.com/docs/5.1/utilities/api/#using-the-api
$utilities: map-merge(
  $utilities,
  (
    "rounded": (
      property: border-radius,
      class: rounded,
      values: (
        null: $border-radius,
        0: 0,
        1: $border-radius-sm,
        2: $border-radius,
        3: $border-radius-lg,
        5: 15px 15px,
        circle: 50%,
        pill: $border-radius-pill
      )
    ),
  )
);