select.form-control {
  text-transform: uppercase;
  
  &:active, &:focus {
    background-color: $lightBlue;
    color: #fff;
  }
}

.form-label {
  line-height: 1.0;
}

.btn-previous:before {
    display: block;
    content: "&darr";
}