footer {
  background-color: #F2F2F2;
  
  li.nav-item {
    margin-right: 30px;
    &:last { margin-right: 0; }
    
    text-transform: uppercase;

    a.nav-link:link, 
    a.nav-link:visited {
      font-size: 1.1rem;
      color: $black;
    }
  }
}

@include media-breakpoint-down(md) {
  footer {
    li.nav-item {
      margin-right: 0px;
    }
  }
}

// https://stackoverflow.com/questions/11173106/apply-style-only-on-ie
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  footer {
    margin-top: 100px;
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
  body > .container > *:last-child{
    padding-bottom: 100px;
  }
}