#survey_submissions-show,
#survey_submissions-complete,
#survey_submissions-update {
  @include media-breakpoint-down(md) {
    img.gecko-leaning {
      height: 100px;
    }
    img.patrol-logo {
      max-width: 50%;
      max-height: 170px;
    }

    .range-labels {
      margin-left: -10.4% !important; 
      margin-right: -10.4% !important; 
    }
    .question {
      textarea {
        height: 160px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .question {
      textarea {
        height: 100px;
      }
    }
  }

  .survey-header {
    background-color: #F2F2F2;
  }

  img.patrol-logo {
    // max-width: 200px;
    max-height: 170px;
  }
  img.gecko-leaning {
    height: 150px;
    margin-right: -5px;
    z-index: 100;
  }

  .btn.btn-success {
    color: #fff;
    border-color: darken($success, 5%);
  }

  .range {
    position: relative;
    height: 5px;

    input {
      width: 100%;
      position: absolute;
      left: 0;
      top: 2px;
      height: 0;
      -webkit-appearance: none;

      &:focus { // override outline/background on focus
        background: none;
        outline: none;
      }

      &::-ms-track { // A little somethin' somethin' for IE
        width: 100%;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
      }
    }

  }

  // Labels below slider
  .range-labels {
    margin: 9px -11.7% 0 -11.7%;
    padding: 0;
    list-style: none;
    
    li {
      position: relative;
      float: left;
      width: 20%;
      text-align: center;
      color: $blue;
      font-size: 14px;
      cursor: pointer;
    }
    
    .active {
      color: $blue;
      font-weight: bold;
    }
    
    .selected::before {
      background: $blue;
    }
    
    .active.selected::before {
      display: none;
    }
  }

}

